<template>
	<div class="app-container">
		<div style="margin-bottom:10px;position: relative;">
			<span>签到功能：</span>
			<el-switch
				v-model="IsOpenValue"
				@change="SignConfigIsOpen"
				active-color="#13ce66" >
			</el-switch>
			
			<div style="position: absolute;right: 20px;top: 0px;">
				<experience-code></experience-code>
			</div>
			
		</div>
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="签到统计" name="1"></el-tab-pane>
			<el-tab-pane label="签到设置" name="2"></el-tab-pane>
			<el-tab-pane label="签到订阅" name="3"></el-tab-pane>
			<el-tab-pane label="签到页设置" name="4"></el-tab-pane>
		</el-tabs>
		<!-- tab1 -->
		<div class="tab-first" v-if="activeName=='1'" v-loading='echartLoading'>
			<div class="title">签到概况</div>
			<div class="content">
				<div class="left">
					<calendar-echart :echartData='echartData'></calendar-echart>
				</div>
				<div class="right">
					<div class="item">
						<div style="width: 50%;text-align:center">总签到用户数<div class="text">{{statisticalData.TotalSignMemberCount}} </div>
						</div>
						<div style="width: 50%;text-align:center">总签到次数<div class="text">{{statisticalData.TotalSignCount}} </div>
						</div>
					</div>
					<div class="item">
						<div style="width: 50%;text-align:center">人均签到次数<div class="text">{{statisticalData.AverageCount}} </div>
						</div>
						<div style="width: 50%;text-align:center">订阅签到提醒用户数<div class="text">{{statisticalData.OpenSignWarnCount}} </div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- tab2 -->
		<div class="tab-second" v-else-if="activeName=='2'">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" v-loading='loading'>
				<el-form-item label="签到活动名称：">
					<el-input v-model="ruleForm.Title" placeholder="最多输入20个字" maxlength="20" style="width:500px;"></el-input>
				</el-form-item>
				<el-form-item label="日签奖励：" required>
					<span v-if="!ruleForm.IsSendPointDay&&(!ruleForm.IsSendCouponDay)" @click="handleDailyCheckReward(0)" class="basic-text-button">设置</span>
					<div class="daily-check-content">
						<div class="reward-name">
							<div v-if="ruleForm.IsSendPointDay">{{ruleForm.PointNumDay}}个积分</div>
							<div v-if="ruleForm.IsSendCouponDay&&ruleForm.CouponListDayDetail&&ruleForm.CouponListDayDetail.length">
								优惠券名称：{{ruleForm.CouponListDayDetail[0].CouponName}}
								<span>
									(赠送数量：{{ruleForm.CouponListDayDetail[0].CouponCount}})
								</span>
							</div>
							<span style="color:#909399;">每日签到成功后可领取该奖励。</span>
						</div>
						<div v-if="ruleForm.IsSendPointDay||ruleForm.IsSendCouponDay">
							<span class="basic-text-button margin-left-style" @click="handleDailyCheckReward(1)">修改奖励</span>
							<span class="error-text-button margin-left-style" @click="handleDeleteDailyCheckReward()">删除奖励</span>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="连签周期："  prop="ContinueSignCycle">
					<el-input style="width:150px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
					 onblur="this.v();if (value==0||value=='-'){value =''}" v-model="ruleForm.ContinueSignCycle"></el-input>
					<span class="margin-left-style">天</span>
					<span style="margin-left:10px;color:#909399;font-size:12px;">若修改周期，该配置将在次日生效</span>
				</el-form-item>
				<el-form-item label="连签奖励：" class="continue-check-content" required>
					<span class="basic-text-button" @click="handleAddContinueReward()">添加奖励</span>
					<span class="remarks">1.周期循环，单个周期内，仅可领取一次连签奖励。若修改周期，将在次日生效</span>
					<span class="remarks">2.若优惠券设置领取次数限制，客户不满足领取条件时将不再发放；若优惠券剩余数量为0，将不再发放。请关注优惠券剩余数量并及时补充</span>
				</el-form-item>
				<el-form-item class="continue-check-content">
					<el-table v-if="ruleForm.ContinueSignSignConfigDetail.length!==0" :data="ruleForm.ContinueSignSignConfigDetail"
					 style="width: 90%;">
						<el-table-column :key="0" label="连签天数">
							<template slot-scope="scope">
								<div>{{scope.row.ContinueSignDay}}天</div>
							</template>
						</el-table-column>
						<el-table-column :key="1" label="奖励">
							<template slot-scope="scope">
								<div v-if="scope.row.IsSendPoint">积分：{{scope.row.PointNum}}</div>
								<div v-if="scope.row.IsSendCoupon&&scope.row.CouponListDetail.length">
									优惠券：{{scope.row.CouponListDetail[0].CouponName}}
									<span v-if="scope.row.CouponListDetail.length&&scope.row.CouponListDetail[0].State&&scope.row.CouponListDetail[0].State!=''"
									 style="color: #F56C6C;margin-left:5px;">
										({{scope.row.CouponListDetail[0].State}})
									</span>
								</div>
								<div v-if="scope.row.IsSendGift&&scope.row.GiftListDetail.length">
									赠品：{{scope.row.GiftListDetail[0].Name}}
									<span style="color: #F56C6C;margin-left:5px;" >{{!scope.row.GiftListDetail[0].IsOpen ? '已下架': scope.row.GiftListDetail[0].Stock<1?'已售罄': ''}}</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column :key="2" label="操作">
							<template slot-scope="scope">
								<el-button class="basic-text-button margin-left-style" @click="handleAddContinueReward(scope.row,scope.$index)"
								 type="text" size="small">修改奖励</el-button>
								<el-button style="color:#F56C6C;" @click="handleDeleteContinueReward(scope.$index)" type="text" size="small">删除奖励</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>
				<el-form-item label="签到规则：">
					<el-input style="width:500px;" v-model="ruleForm.SignRules" type="textarea" :autosize="{ minRows: 4}" maxlength="500"
					 placeholder="请填写签到规则，若未填写，则使用默认规则：
					1.每日签到可以获得日签奖励，在单个周期内连续签到可以获得连签奖励，同1个周期内最多可领取1次；
					2.每日最多可签到1次，断签则会重新计算连签天数；
					3.活动以及奖励详情可联系商家客服了解"></el-input>
				</el-form-item>
			</el-form>
			<div class="footer" v-if="this.activeName=='2'">
				<el-button type="primary" style="width:200px" @click="saveEditForm" v-loading="loading">保存</el-button>
			</div>
		</div>

		<div v-else-if="activeName=='3'" v-loading='echartLoading'>
			<div>
				<div class = "flexrowstart">
					<div class = "leftfont">签到订阅奖励：</div>
					<div style="margin-left:15px">
						<div class = "flexRow">
							<span v-if="subscribemsg.PointNumSubscribe" style="margin-right:20px;font-size:14px">
								{{subscribemsg.PointNumSubscribe}}个积分
							</span>
							<el-button type="text" style="padding:0px" @click="subscribeshow=true;subscriberuform.integral=subscribemsg.PointNumSubscribe">设置奖励</el-button>
							<el-button v-if="subscribemsg.PointNumSubscribe" type="text" style="padding:0px;color:#f56c6c" @click="subscribemsg.PointNumSubscribe=''">删除奖励</el-button>
						</div>
						<div class = "grayfont" style="margin:5px 0px 0px 5px">
							客户开启签到订阅通知后，可获得该奖励，若客户取消订阅，奖励回收；奖励仅发放1次
						</div>
					</div>
				</div>
				<div class = "flexrowstart" style="margin-top:30px">
					<div class = "leftfont">签到订阅通知：</div>
					<div style="margin-left:15px">
						<div class = "grayfont" style="margin-left:5px">
							系统将在每天晚上8点给订阅签到的客户主动推送公众号模板通知，提醒客户完成当日签到
						</div>
						<el-card class = "card" body-style = "padding:0px">
							<div style="padding:20px 20px 0px 20px">
								<div class = "cardtitle">服务完成通知</div>
								<div class = "card-grayfont fonthidden" style="margin-top:5px">{{subscribemsg.NoticeSubscribe.first}}</div>
								<div style="margin-top:15px">
									<div class = "flexRow">
										<div class="card-leftfont card-grayfont">服务项目：</div>
										<div class = "card-grayfont">{{subscribemsg.NoticeSubscribe.keyword1}}</div>
									</div>
									<div class = "flexRow">
										<div class="card-leftfont card-grayfont">时间：</div>
										<div class = "card-grayfont">通知发送时间（示例：2021-11-27  20:00:00）</div>
									</div>
									<div class = "flexRow">
										<div class="card-leftfont card-grayfont">备注：</div>
										<div class = "card-grayfont">{{subscribemsg.NoticeSubscribe.remark}}</div>
									</div>
								</div>
							</div>
							<div style="border-bottom:1px solid #dddddd;margin:20px 0px 10px 0px"></div>
							<div style="padding:5px 20px 10px 20px;justify-content: space-between;color:#999999" class = "flexRow">
								<div class = "fonthidden" style="max-width:300px;font-size:14px">
									<span>通知链接至：
										{{subscribemsg.NoticeSubscribe.urlname}}
									</span>
								</div>
								<el-button type="text" style="margin:0px;padding:0px" @click="editSignin">编辑</el-button>
							</div>
						</el-card>
					</div>
				</div>
			</div>
			<div class="footer" v-if="this.activeName=='3'">
				<el-button type="primary" style="width:200px" @click="saveEditForm3" v-loading="loading">保存</el-button>
			</div>
		</div>

		<div v-else-if="activeName=='4'" v-loading='echartLoading'>
			<div class = "flexrowstart">
				<div style="width:330px;flex:0 0 auto">
					<img :src="imgUrl+'/image/Signinleft3.png'" style="width:100%" alt="">
				</div>
				<div style="margin-left:10px;flex:1">
					<div class = "flexrowstart">
						<div class = "leftfont">签到页广告位：</div>
						<div>
							<div>
								<el-checkbox v-model="picturecheck" style="margin:0px 0px 10px 0px">图片广告</el-checkbox>
								<div v-if="picturecheck">
									<div class = 'grayfont'>
										1.图片建议尺寸：宽750px，高200px
										<br>
										2.图片大小请控制在2M以内
									</div>
									<el-upload :action="imgApi" accept="image/*" list-type="picture-card" :show-file-list="false" :on-success="onSuccess"
									class = "uploadBox" :before-upload ="beforeUpload" style="margin-top:10px">
										<img v-if="imgadvertising.AdvertiseImgUrl" slot="default"
										 style="width:100%;height:100%" :src="imgUrl+imgadvertising.AdvertiseImgUrl" alt="" />
										<i v-else slot="default" class="el-icon-plus"></i>
									</el-upload>
									<div style="margin-top:10px">
										<span>图片链接：</span>
										<el-dropdown @command="handleCommand">
											<el-button type="text">
												<div class="flexRow">
													<div  class = "fonthidden" style="max-width:200px" v-if="imgadvertising.MenuType||imgadvertising.CustomerServiceReplyContentId">
														<span>{{imgadvertising.MenuType?imgadvertising.PageName:imgadvertising.CommunityGroupCodeTitle}}</span>
														</div>
													<span v-else>请选择链接页面</span>
													<i class = "el-icon-arrow-down" style="margin-left:5px"></i>
												</div>
											</el-button>
											<i class = "el-icon-close" v-if="imgadvertising.MenuType||imgadvertising.CustomerServiceReplyContentId"
												@click="imgremovepage"	style="color:#409eff;margin-left:10px;font-size:14px;cursor: pointer;"></i>
											<el-dropdown-menu slot="dropdown">
												<el-dropdown-item command="1">选择小程序内页面</el-dropdown-item>
												<el-dropdown-item command="2">进入小程序客服</el-dropdown-item>
											</el-dropdown-menu>
										</el-dropdown>
									</div>
								</div>
							</div>
							<div style="margin:15px 0px">
								<el-checkbox @change="chooseshopcheck" v-model="shopcheck">商品广告</el-checkbox>
								<div v-if="shopcheck" >
									<div class = "flexRow">
										<el-button type="text" @click="addProductDialogShow=true">选择商品</el-button>
										<span class = "grayfont" style="margin-left:10px">最多可选择20个商品；拖拽商品图片可排序</span>
									</div>
									<div>
										<draggable  :list="selectedList" :options="{animation: 100, handle:'.shopbox'}"
										 class = "draggablebox">
											<div class = "shopbox" v-for="(v,i) in selectedList" :key="i">
												<div class = "imgbox">
													<div class = "position-img">
														<img :src="imgUrl+v.ImgUrl" style="width:100%;height:100%" alt="">
														<div class = "icon-close" @click="removeshop(i)">
															<i class = "el-icon-close"></i>
														</div>
													</div>
												</div>
												<div style="flex:1;padding:5px" class = "fontbox">
													<el-tooltip popper-class="tooltipitem" effect="dark" :content="v.Name" placement="top">
														<div class = "fonthidden2">{{v.Name}}</div>
													</el-tooltip>
												</div>
											</div>
										</draggable>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="footer" v-if="activeName=='4'">
						<el-button type="primary" style="width:200px" @click="saveEditForm4" v-loading="loading">保存</el-button>
					</div>
				</div>
			</div>
		</div>


		<!-- 日签奖励dialog -->
		<el-dialog title="设置日签奖励" :visible.sync="dailySelectDialog" width="900px" class="dialog">
			<div class="content">
				<el-form ref="dailyForm">
					<el-radio @change="handleDailySelect" class="dialog-check-radio" v-model="dailySelect.IsSendPointDay" :label="true">
						送
						<el-input style="width:200px;margin:0 10px;" :disabled="dailySelect.IsSendPointDay==false" v-model="dailySelect.PointNumDay"
						 onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
						 onblur="this.v();if (value==0||value=='-'){value =''}"></el-input> 积分
					</el-radio>
					<el-radio @change="handleDailySelect" class="dialog-check-radio" v-model="dailySelect.IsSendPointDay" :label="false">
						<span>送优惠券</span>
						<span v-if="dailySelect.IsSendPointDay==false" class="basic-text-button margin-left-style" @click="handleSelectCouponShow">选择优惠券</span>
					</el-radio>
					<div>
						<el-table v-if="dailySelect.CouponListDayDetail&&dailySelect.CouponListDayDetail.length" :data="dailySelect.CouponListDayDetail">
							<el-table-column :key="4" prop="CouponName" label="优惠券"></el-table-column>
							<el-table-column :key="5" prop="UseTypeValue" label="类型"></el-table-column>
							<el-table-column :key="6" prop="CouponContent" label="优惠内容"></el-table-column>
							<el-table-column :key="7" prop="ActivityRangeTypeValue" label="适用商品"></el-table-column>
							<el-table-column :key="8" label="赠送数量/人">
								<template slot-scope="scope">
									<input style="width:100px;height:36px" v-model="scope.row.CouponCount" onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
									 onblur="this.v();if ( value==0||value=='-'){value =''}" />
								</template>
							</el-table-column>
							<el-table-column label="操作">
								<template>
									<el-button type="text" style="color:#F56C6C;" @click="handleDeleteDailyCoupon">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</el-form>

				<!-- <span style="margin-left:20px;" v-if="dailySelect.coupon.name!=''">优惠券名称：{{dailySelect.coupon.name}}</span> -->
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button class="button" @click="cancelAddCoupon">关闭</el-button>
				<el-button class="button" @click="handleSaveDailySelect" type="primary">确定</el-button>
			</div>
		</el-dialog>

		<!-- 连签奖励dialog -->
		<el-dialog title="设置连签奖励" :visible.sync="editContinueDialog" width="1000px" class="dialog">
			<div class="content">
				<el-form :model="continueSelect" ref="continueForm" :rules="continueRules">
					<el-form-item label="连签天数：" :inline-message=true prop="ContinueSignDay" :rules="continueRules.ContinueSignDay">
						<el-input style="width:200px;" v-model="continueSelect.ContinueSignDay" prop="continueCheckDays" onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0){value =''}"
						 onblur="this.v();if (value==0||value=='-'){value =''}"></el-input>
						<span class="margin-left-style">天</span>
					</el-form-item>
					<!-- 送积分 -->
					<el-form-item class="check-item">
						<el-checkbox @change="handleContinuePointCheck" v-model="continueSelect.IsSendPoint">
							<el-form-item prop="PointNum" :inline-message=true :rules="continueSelect.IsSendPoint?continueRules.PointNum:continueRules.nocheck">
								送
								<el-input :disabled="!continueSelect.IsSendPoint" style="margin: 0 5px;width:200px;" v-model="continueSelect.PointNum"
								 onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
								 onblur="this.v();if (value==0||value=='-'){value =''}"></el-input>积分
							</el-form-item>
						</el-checkbox>
					</el-form-item>
					<!-- 选优惠券 -->
					<el-form-item class="check-item">
						<el-checkbox @change="handleContinueCouponCheck" v-model="continueSelect.IsSendCoupon">
							送优惠券
						</el-checkbox>
						<span v-if="continueSelect.IsSendCoupon" style="color:#409EFF;cursor: pointer;margin-left:20px;" @click="handleCoutinueCoupons">选择优惠券</span>
					</el-form-item>

					<!-- 优惠券列表 -->
					<el-form-item class="check-item" v-if="continueSelect.CouponListDetail&&continueSelect.CouponListDetail.length">
						<el-table :data="continueSelect.CouponListDetail" style="width: 90%;margin-left:20px;">
							<el-table-column :key="9" prop="CouponName" label="优惠券">
								<template slot-scope="scope">
									<div class="right">
										<div class="name">{{scope.row.CouponName}}</div>
										<span style="color: #E51C23;font-size:12px;">{{scope.row.State}}</span>
									</div>
								</template>
							</el-table-column>
							<el-table-column :key="10" prop="UseTypeValue" label="类型"></el-table-column>
							<el-table-column :key="12" prop="CouponContent" label="优惠内容"></el-table-column>
							<el-table-column :key="11" prop="ActivityRangeTypeValue" label="适用商品"></el-table-column>
							<el-table-column :key="13" label="赠送数量/人">
								<template slot-scope="scope">
									<input style="width:100px;height:36px;padding:5px;" v-model="scope.row.CouponCount " onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
									 onblur="this.v();if ( value==0||value=='-'){value =''}" />
								</template>
							</el-table-column>
							<el-table-column label="操作">
								<template>
									<el-button style="color:#F56C6C;" @click="deleteContinuCoupon" type="text" size="small">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</el-form-item>

					<!-- 送赠品 -->
					<el-form-item class="check-item" style="margin-bottom:20px">
						<el-checkbox @change="handleContinueProCheck" v-model="continueSelect.IsSendGift">
							送赠品
						</el-checkbox>
						<span v-if="continueSelect.IsSendGift" style="color:#409EFF;cursor: pointer;margin-left:20px;" @click="handleCoutinuePro">选择商品</span>
					</el-form-item>

					<!-- 赠品列表 -->
					<el-form-item class="check-item" v-if="continueSelect.GiftListDetail&&continueSelect.GiftListDetail.length">
						<el-table :data="continueSelect.GiftListDetail" style="width: 90%;margin-left:20px;">
							<el-table-column :key="14" prop="Name" width="370" label="商品">
								<template slot-scope="scope">
									<div class="dialog-name-content">
										<img :src='imgUrl+scope.row.ImgUrl' />
										<div class="right">
											<div class="name">
												<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
											</div>
											<span style="color: #F56C6C;margin-left:5px;" >{{!scope.row.IsOpen ? '已下架': scope.row.Stock<1?'已售罄': ''}}</span>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column :key="15" prop="ProductSpec" label="规格"></el-table-column>
							<el-table-column :key="16" prop="ProductPrice" label="价格"></el-table-column>
							<el-table-column :key="17" prop="Stock" label="库存"></el-table-column>
							<el-table-column :key="18" label="赠送数量" width="120px">
								<template slot-scope="scope">
									<input style="width:100px;height:36px;padding:5px;" v-model="scope.row.ProductCount" onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
									 onblur="this.v();if ( value==0||value=='-'){value =''}" />
								</template>
							</el-table-column>
							<el-table-column label="操作">
								<template>
									<el-button style="color:#F56C6C;" @click="deleteContinuPro" type="text" size="small">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button class="button" @click="editContinueDialog=false">关闭</el-button>
				<el-button class="button" @click="saveAddContinueReward('continueForm')" type="primary">确定</el-button>
			</div>
		</el-dialog>

		<!-- 选择日签优惠券 -->
		<el-dialog title="选择优惠券" :visible.sync="selectCouponShow" width="1000px" class="dialog data-dialog">
			<select-coupon :couponCheckedId='couponCheckedId' @getSelectCoupon='getSelectCoupon' :visible.sync="selectCouponShow"
			 v-if="selectCouponShow"></select-coupon>
		</el-dialog>
		<!-- :couponCheckedVal='couponCheckedVal' -->

		<!-- 选择连续签优惠券 -->
		<el-dialog title="选择优惠券" :visible.sync="ContinueSelectCoupon" width="1000px" class="dialog data-dialog">
			<select-coupon :couponCheckedId='couponCheckedId' @getSelectCoupon='getContinueSelectCoupon' :visible.sync="ContinueSelectCoupon"
			 v-if="ContinueSelectCoupon"></select-coupon>
		</el-dialog>

		<!-- 选择商品-->
		<el-dialog title="选择商品" :visible.sync="selectProShow" width="1000px" class="dialog data-dialog">
			<select-produce :proCheckedId='proCheckedId' @getSelection='getSelectList' :visible.sync="selectProShow" v-if="selectProShow"></select-produce>
		</el-dialog>
		
		<!-- 设置订阅奖励 -->
		<el-dialog :visible.sync="subscribeshow" title="设置订阅奖励" width="450px" top="40vh" @close="closesubscribe">
			<el-form :model="subscriberuform" :rules="subscriberules" ref="subscriberuform" label-width="150px">
				<el-form-item prop="integral" label="赠送积分：">
					<el-input v-model="subscriberuform.integral" style="width:150px;margin-right:15px"></el-input>个
				</el-form-item>
				<div style="margin-top:10px;text-align:right">
					<el-button @click="closesubscribe">取消</el-button>
					<el-button type="primary" @click="subscribeconfirm('subscriberuform')">确定</el-button>
				</div>
			</el-form>
		</el-dialog>

		<!-- 编辑签到通知 -->
		<el-dialog :visible.sync="Signinshow" v-loading="Signinloading" title="编辑签到通知" @close="closesingin" width="1000px" custom-class="dialogpad">
			<div class = "flexrowstart" style="align-items:stretch">
				<div class = "Signinleft">
					<el-form :model="Signinruform" :rules="Signinrules" ref="Signinruform" label-width="120px">
						<el-form-item label="通知标题：">
							<span>服务完成通知</span>
						</el-form-item>
						<el-form-item prop="first" label="前言：">
							<el-input v-model="Signinruform.first" maxlength="30"></el-input>
						</el-form-item>
						<el-form-item prop="keyword1" label="服务项目：">
							<el-input v-model="Signinruform.keyword1" maxlength="30"></el-input>
						</el-form-item>
						<el-form-item label="时间：">
							<span>通知发送时间（示例：2021-11-27  20:00:00）</span>
						</el-form-item>
						<el-form-item prop="remark" label="备注：">
							<el-input v-model="Signinruform.remark" maxlength="30"></el-input>
						</el-form-item>
						<el-form-item label="通知链接：">
							<el-button type="text" @click="shopVisible=true">
								<div class="flexRow" style="max-width:200px">
									<span class = "fonthidden" v-if="Signinruform.urlname">{{Signinruform.urlname}}</span>
									<span v-else>请选择链接页面</span>
									<i class = "el-icon-arrow-down" style="margin-left:5px"></i>
								</div>
							</el-button>
							<i class = "el-icon-close" v-if="Signinruform.urlname" @click="removepage"
							 style="color:#409eff;margin-left:10px;font-size:14px;cursor: pointer;"></i>
						</el-form-item>
					</el-form>
					<div style="margin-top:10px;text-align:center">
						<el-button @click="closesingin">取消</el-button>
						<el-button type="primary" @click="singinconfirm('Signinruform')">确定</el-button>
					</div>
				</div>
				<div class = "rightimgbox">
					<img :src="imgUrl+'/image/Signintoinform2.png'" class = "imgposition" alt="">
					<el-card class = "card contetbox" body-style = "padding:0px">
						<div style="padding:20px 20px 0px 20px">
							<div class = "flexRow" style="justify-content: space-between;">
								<div class = "cardtitle">服务完成通知</div>
								<div style="font-size:20px;color:#999999">···</div>
							</div>
							<div class = "card-grayfont fonthidden">{{Signinruform.first}}</div>
							<div style="margin-top:15px">
								<div class = "flexRow">
									<div class="card-leftfont card-grayfont">服务项目：</div>
									<div class = "card-grayfont fonthidden">{{Signinruform.keyword1}}</div>
								</div>
								<div class = "flexRow">
									<div class="card-leftfont card-grayfont">时间：</div>
									<div class = "card-grayfont fonthidden">2021-11-27 20:00:00</div>
								</div>
								<div class = "flexRow">
									<div class="card-leftfont card-grayfont">备注：</div>
									<div class = "card-grayfont fonthidden">{{Signinruform.remark}}</div>
								</div>
							</div>
						</div>
						<div style="border-bottom:1px solid #dddddd;margin:10px 0px"></div>
						<div style="padding:0px 20px 5px 20px;justify-content: space-between;" class = "flexRow">
							<div class = "flexRow" style="color:#999999;font-size:14px">
								<img :src="imgUrl+'/image/pagesharetoplogo.png'" style="width:20px;height:20px" alt="">
								<span style="margin-left:5px">小程序名称</span>
							</div>
							<div class = "flexRow" style="color:#999999;font-size:14px">
								<img :src="imgUrl+'/image/pagesharebottom.png'" style="width:13px;height:13px" alt="">
								<span style="color:#303133;margin-left:5px">小程序</span>
								<i class = "el-icon-arrow-right" style="font-size:16px"></i>
							</div>
						</div>
					</el-card>
				</div>
			</div>
		</el-dialog>

		<!-- 选择页面 -->
		<linkModal :showLiveTab="true" :visible="shopVisible" @change="dataChange" @cancel="shopVisible=false" key="1"></linkModal>

		<linkModal :showLiveTab="true" :visible="shopVisible2" @change="dataChange2" @cancel="shopVisible2=false" key="2"></linkModal>

		<!-- 进入小程序客服 -->
		<replyModal :visible="replyVisible" :data="pagemsg2" @change="dataChangerep" @cancel="replyVisible=false"></replyModal>

		<!-- 选择商品 -->
		<el-dialog title="选择商品" :visible.sync="addProductDialogShow" width="1300px" class="dialog">
			<selectshop :params="['KeyWord', 'ProductType', 'ProductGroupIds', 'ProductBrandId']" :selectedData="selectedList"
			 @getSelectproList="getSelectproList" :goodsMaxlength='20' :visible.sync="addProductDialogShow" v-if="addProductDialogShow">
			</selectshop>
		</el-dialog>
	</div>
</template>
<script>
	import calendarEchart from "./calendarEchart.vue";
	import selectCoupon from '@/components/SelectCouponDialog'
	import selectProduce from '@/components/SelectSingleProduce'
	import apiList from '@/api/other'
	import config from '@/config/index';
	import linkModal from '@/views/components/linkModal';
	import replyModal from '@/views/components/autoReplyModal.vue';
	import selectshop from '@/views/goods/selectgoodsElement.vue'

	import draggable from "vuedraggable";
	
	import experienceCode from '@/components/experienceCode';
	
	export default {
		components: {
			calendarEchart,
			selectCoupon,
			selectProduce,
			experienceCode,
			linkModal,
			replyModal,
			selectshop,
			draggable
		},
		data() {
			var checkContinueSignCycle = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请设置连签周期'))
				} else {
					if (value > 365 || value < 2) {
						this.ruleForm.ContinueSignCycle = ''
						return callback(new Error(`请设置正确的连签天数，2≦天数≦365`))
					} else {
						return callback()
					}
				}
			}
			var checkContinueSignDay = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请设置连签天数'))
				} else {
					let num =Number(value) 
					let maxNum = Number( this.ruleForm.ContinueSignCycle) 
					if (num >maxNum || value < 2) {
						return callback(new Error(`请设置正确的连签天数，2≦天数≦${this.ruleForm.ContinueSignCycle}`))
					} else {
						return callback()
					}
				}
			}
			var checkPointNum = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请设置积分'))
				} else {
					return callback()
				}
			}
			var checkNo = (rule, value, callback) => {
				return callback()
			}
			var checkintegral = (rule, value, callback) => {
				if (value < 1 || value > 99999 || value != parseInt(value)) {
					return callback(new Error('积分请输入在1~99999之间，整数'))
				} else {
					return callback()
				}
			}
			return {
				IsOpenValue:false,
				currentCycle: 0,
				imgApi:config.UPLOAD_IMG,
				imgUrl: config.IMG_BASE,
				echartLoading: false,
				loading: false,
				statisticalData: {
					TotalSignMemberCount: 0,
					TotalSignCount: 0,
					AverageCount: 0,
					OpenSignWarnCount: 0,
					SignStatistics: []
				},
				echartData: {
					x: [],
					y: []
				},
				activeName: '1',
				ruleForm: {
					ContinueSignCycle: '',
					PointNumDay: '',
					CouponListDay: [],
					CouponListDayDetail: [],
					ContinueSignSignConfigDetail: [],
					IsSendPointDay: false,
					IsSendCouponDay: false,
					SignRules: '',
				},
				rules: {
					ContinueSignCycle: [{
						trigger: 'blur',
						required:true,
						validator: checkContinueSignCycle
					}]
				},
				dailySelect: {
					IsSendPointDay: true,
					PointNumDay: '',
					CouponListDayDetail: [],
				},
				editRadio: '1',
				dailySelectDialog: false,
				editDialog: false,
				selectCouponShow: false,
				ContinueSelectCoupon: false,
				editContinueDialog: false,
				selectProShow: false,
				continueSelect: {
					ContinueSignDay: '',
					PointNum: '',
					IsSendPoint: false,
					IsSendCoupon: false,
					IsSendGift: false,
					CouponList: [],
					CouponListDetail: [],
					GiftList: [],
					GiftListDetail: []
				},
				continueRules: {
					ContinueSignDay: [{
						trigger: 'blur',
						validator: checkContinueSignDay
					}],
					PointNum: [{
						trigger: 'blur',
						validator: checkPointNum
					}],
					nocheck: [{
						trigger: 'blur',
						validator: checkNo
					}],
				},
				page: {
					total: 0,
					current: 1,
					size: 5,
				},
				couponCheckedId: 0,
				couponCheckedVal: {},
				proCheckedId: 0,
				proCheckedVal: {},
				continueEditIndex: null,
				//签到订阅
				subscribemsg:{
					PointNumSubscribe:'',
					NoticeSubscribe:{
						first:'',
						keyword1:'',
						remark:'',
						url:'',
					}
				},
				subscribeshow:false,
				subscribeloading:false,
				subscriberuform:{
					integral:'',
				},
				subscriberules:{
					integral:[{
						trigger: 'blur',
						validator: checkintegral
					}]
				},

				Signinshow:false,
				Signinloading:false,
				Signinruform:{
					first:'企店官方商城提醒你，你今天还未签到哦',
					keyword1:'每日签到提醒',
					remark:'点击此处快速签到',
					url:'',
					urlname:'',
				},
				Signinrules:{
					first:[
						{ required: true, message: '前言内容不可为空', trigger: 'blur' }
					],
					keyword1:[
						{ required: true, message: '服务项目内容不可为空', trigger: 'blur' }
					],
					remark:[
						{ required: true, message: '备注内容不可为空', trigger: 'blur' }
					],
				},
				//签到页设置
				shopVisible:false,

				picturecheck:false,
				shopcheck:false,

				advertisinglist:[],

				img:'',
				imgpagemsg:{},

				shopVisible2:false,
				pagemsg2:{},
				replyVisible:false,
				addProductDialogShow:false,
				selectedList:[],
			}
		},
		// beforeMount(){
		//   this.getData()
		// },
		computed: {
			imgadvertising(){
				let msg =	this.advertisinglist.find((v)=>{
					return v.AdvertiseSpaceType == 0
				})
				if(!msg){
					return {}
				}
				return msg
			},
			shopadvertising(){
				let msg =	this.advertisinglist.find((v)=>{
					return v.AdvertiseSpaceType == 1
				})
				if(!msg){
					return {}
				}
				return msg
			},
		},
		created() {
			this.getData()
			this.getSign()
			this.getFormData()
		},
		methods: {
			imgremovepage(){
				this.advertisinglist = this.advertisinglist.map((v)=>{
					if(v.AdvertiseSpaceType == 0){
						v.MenuType = null
						v.CustomerServiceReplyContentId = null
					}
					return v
				})
			},
			chooseshopcheck(val){
				// console.log(val)
				if(val){
					let data = {
						type:1,
						obj:[
							{key:'AdvertiseSpaceType',value:1},
						]
					}
					this.changeadvertisinglist(data)
				}
			},
			changeadvertisinglist(data){
				if(this.advertisinglist&&this.advertisinglist.length){
					this.advertisinglist.forEach((v)=>{
						if(v.AdvertiseSpaceType == data.type){
							data.obj.map((x)=>{
								v[x.key] = x.value
							})
						}else{
							// console.log(1)
							this.newpush(data)
						}
						// console.log(v)
						// return v
					})
				}else{
					this.newpush(data)
				}
				console.log(this.advertisinglist,66)
			},
			newpush(data){
				let msg = {
					Id:0,
					AdvertiseSpaceType:data.type,
				}
				data.obj.map((x)=>{
					msg[x.key] = x.value
				})
				// console.log(msg)
				this.advertisinglist.push(msg)
			},
			saveEditForm3(){
				// if(!this.subscribemsg.PointNumSubscribe){
				// 	this.$message.error('请设置签到订阅奖励')
				// }else{
					this.tosaveEditForm3()
				// }
			},
			async tosaveEditForm3(){
				this.loading = true
				try{
					let data = {SignConfigSubscribeDto:JSON.parse(JSON.stringify(this.subscribemsg))}
					let res = await apiList.SignConfigSubscribeEdit(data)
					if(res.IsSuccess){
						this.$message.success('操作成功')
						this.getsubscribe()
					}
				}finally{
					this.loading = false
				}
			},
			saveEditForm4(){
				let haceimg = this.advertisinglist.find((v)=>{return v.AdvertiseSpaceType==0})
				if(this.picturecheck &&(!haceimg || !haceimg.AdvertiseImgUrl)){
					this.$message.error('请上传图片')
					return
				}
				this.tosaveEditForm4()
			},
			async tosaveEditForm4(){
				this.loading = true
				try{
					let list = JSON.parse(JSON.stringify(this.advertisinglist))
					// console.log(list,111)
					let data = {SignConfigAdvertiseSpaceToSaveDto:[]}
					if(this.picturecheck){
						data.SignConfigAdvertiseSpaceToSaveDto.push(list.find((v)=>{return v.AdvertiseSpaceType==0}))
					}
					if(this.shopcheck){
						let msg = list.find((v)=>{return v.AdvertiseSpaceType==1})
						// console.log(list,'msg')
						if(msg&&msg.RelatedAdvertiseProductToSave&&msg.RelatedAdvertiseProductToSave.length){
							msg.RelatedAdvertiseProductIdList = msg.RelatedAdvertiseProductToSave.map((v)=>{
								return v.ProductId
							})
						}
						data.SignConfigAdvertiseSpaceToSaveDto.push(msg)
					}
					// console.log(data)
					let res = await apiList.SignConfigAdvertiseSpaceSave(data)
					if(res.IsSuccess){
						this.$message.success('操作成功')
						this.getadvertising()
					}
				}finally{
					this.loading = false
				}
			},
			editSignin(){
				this.Signinshow = true
				this.Signinruform = JSON.parse(JSON.stringify(this.subscribemsg.NoticeSubscribe))
			},
			removeshop(i){
				this.selectedList.splice(i,1)
			},
			getSelectproList(list){
				// console.log(list)
				this.selectedList = list
				// let isd = list.map((v)=>{
				// 	return v.ProductId
				// })
				this.addProductDialogShow = false
				let data = {
					type:1,
					obj:[
						{key:'RelatedAdvertiseProductToSave',value:list},
					]
				}
				this.changeadvertisinglist(data)
			},
			dataChangerep(e){
				// console.log(e)
				let data = {
					type:0,
					obj:[
						{key:'PageName',value:null},
						{key:'PagePath',value:null},
						{key:'MenuType',value:null},
						{key:'CustomerServiceReplyContentId',value:e.data.Id},
						{key:'CommunityGroupCodeTitle',value:e.data.CommunityGroupCodeTitle},
					]
				}
				this.changeadvertisinglist(data)
			},
			dataChange2(e){
				// console.log(e)
				let data = {
					type:0,
					obj:[
						{key:'PageName',value:e.text},
						{key:'PagePath',value:this.geturl(e).url},
						{key:'MenuType',value:this.geturl(e).type},
						{key:'CustomerServiceReplyContentId',value:null},
						{key:'CommunityGroupCodeTitle',value:null},
					]
				}
				this.changeadvertisinglist(data)
			},
			handleCommand(e){
				// console.log(e)
				if(e==1){
					this.shopVisible2 = true
				}else{
					this.replyVisible = true
				}
			},
			onSuccess(file){
				// console.log(file)
				// this.img = file[0]
				let data = {
					type:0,
					obj:[
						{key:'AdvertiseImgUrl',value:file[0]},
					]
				}
				this.changeadvertisinglist(data)
			},
			beforeUpload(file){
				const size = file.size / 1024 / 1024 < 2;
				const testtype = ['image/bmp','image/png','image/tif','image/gif','image/jpeg','image/jpg','image/webp']
				if(testtype.indexOf(file.type) == -1){
					this.$message.error('请选择图片上传');
					return false
				}else if (!size) {
					this.$message.error('图片大小请控制在2M以内');
					return false
				}
			},
			singinconfirm(formName){
				this.$refs[formName].validate((valid) => {
          if (valid) {
						this.Signinshow = false
			 			this.subscribemsg.NoticeSubscribe =	JSON.parse(JSON.stringify(this.Signinruform))
						 console.log(this.subscribemsg,22)
          } else {
            return false;
          }
        });
			},
			closesingin(){
				this.Signinshow = false
				this.$refs.Signinruform.resetFields()
			},
			dataChange(msg){
				// console.log(msg)
				this.Signinruform.urlname  = msg.text
				this.Signinruform.url = this.geturl(msg).url
			},
			geturl(record){
				let data = {
					url:'',
					type:'',
				}
				if (record.type == "classify") {
          data.url = 'pages/productGroup/productGroup?id=' + record.data.Id
					data.type = 1
        } else if (record.type == "functional") {
          // 全部商品
					data.type = 2
          if (record.data.id == 1) {
            data.url = 'pages/index/index'
          } else if (record.data.id == 2) {
            data.url = 'pages/allProduct/allProduct'
          } else if (record.data.id == 3) {
            // 个人中心
            data.url = 'pages/personalCenter/mine/mine'
          } else if (record.data.id == 4) {
            // 购物车
            data.url = 'pages/shoppingCart/shoppingCart'
          } else if (record.data.id == 5) {
            // 积分中心
            data.url = 'pages/pointsCenter/pointsCenter'
          } else if (record.data.id == 6) {
            // 每日签到
            data.url = 'pages/userSign/userSign'
          } else if (record.data.id == 7) {
            // 客服
            data.url = 'pages/customerService/customerService'
          } else if (record.data.id == 8) {
            // 订单列表
            data.url = 'pages/order/myOrder/myOrder'
          } else if (record.data.id == 9) {
            // 分销礼包
            data.url = 'pages/invitation/invitationCard/invitationCard'
          }else if (record.data.id == 10) {
            // 成为会员
            data.url = 'pageA/pages/openSuccess/openSuccess'
          }else if (record.data.id == 11) {
            // 助力直播列表
            data.url = 'pageA/pages/live/live'
          }else if (record.data.id == 12) {
            // 扫码购
            data.url = 'pageA/pages/scan/scan'
          }else if (record.data.id == 13) {
            // 充值中心
            data.url = 'pageA/pages/balanceRecharge/balanceRecharge'
          }else if (record.data.id == 14) {
            // 群分享
            data.url = 'pageA/pages/groupShare/groupShare'
          }else if (record.data.id == 15) {
            // 全部信息页
            data.url = 'pageA/pages/information/information'
          }else if (record.data.id == 16) {
						// 短视频带货
						data.url =  'pageC/pages/shortVideos/record'
					}else if (record.data.id == 17) {
						// 积分码
						data.url = 'pageA/pages/receive-point/receive-point'
					}else if (record.data.id == 18) {
						// 舌诊
						data.url = 'pageC/pages/tongueDiagnosis/home'
					}else if (record.data.id == 19) {
						// 企店即创主页
						data.url = 'pageC/pages/AIGC/home'
					}
        } else if (record.type == "product") {
          data.url = 'pages/detail/detail?id=' + record.data.Id
					data.type = 3
        } else if (record.type == "custom") {
					data.type = 4
          data.url = 'pages/cusPage/cusPage?id=' + record.data.Id
        }else if (record.type == 'truntable'){
					data.type = 6
          data.url = 'pageA/pages/activity/truntable/truntable?id=' + record.data.Id
        } else if (record.type == 'live'){
					data.type = 5
          data.url = 'plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin?room_id=' + record.data.RoomId
        }else if (record.type == 'coupon'){
					data.type = 7
          data.url = 'pages/couponCenter/receive/receive?id='+record.data.Id+'&uid='+(record.data.uid || '')
        }else if (record.type == 'help') {
					data.type = 8
          data.url = 'pages/helpDetail/helpDetail?id=' + record.data.Id + '&activityId=' + record.data.ActivityFullId
        }else if (record.type == 'group') {
					data.type = 9
          data.url = 'pages/groupDetail/groupDetail?id=' + record.data.ProductId + '&fullId=' + record.data.ActivityFullId
        }else if (record.type == 'questionnaire') {
					data.type = 10
          data.url = 'pageA/pages/questionnaire/questionnaire?&referer=2&id=' + record.data.Id;
        }else if (record.type == 'taketest') {
					data.type = 11
					data.url = `pageC/pages/goodnessTest/home?id=` + record.data.Id
				}else if (record.type == 'contentgroup') {
					data.type = 12
					data.url = `pageA/pages/information/informationGroup?id=` + record.data.Id;
				}else if (record.type == 'singlecontent') {
					data.type = 13
					data.url = `pageA/pages/information/detail?id=` + record.data.Id;
				}else if (record.type == 'helpPro') {
					data.type = 14
					data.url = `pageC/pages/helpPro/helpPro?id=` + record.data.ProductId + '&activityId=' + record.data.ActivityFullId;
				}
				return data
			},
			removepage(){
				// console.log(1)
				// this.Signinruform.pagemsg = {}
				this.Signinruform.urlname = ''
				this.Signinruform.url = ''
			},
			closesubscribe(){
				this.subscribeshow = false
				this.subscriberuform = {
					integral:''
				}
				this.$refs.subscriberuform.resetFields()
			},
			subscribeconfirm(formName){
				this.$refs[formName].validate((valid) => {
          if (valid) {
						this.subscribemsg.PointNumSubscribe = JSON.parse(JSON.stringify(this.subscriberuform.integral))
						this.subscribeshow = false
            console.log('赠送积分')
          } else {
            return false;
          }
        });
			},
			async getSign(){
				let result = await apiList.SignConfigIsOpenState({})
				this.IsOpenValue = result.Result.IsOpen
			},
			async SignConfigIsOpen(){
				let result = await apiList.SignConfigIsOpen({
					 IsOpen: this.IsOpenValue
				})
				this.$message({showClose: true,
					type:'success',
					message:result.Message
				})
			},
			handleClick(e) {
				if (e.name == 1||e.name == 2) {
					this.getFormData()
					this.getData()
				}else if(e.name==3){
					this.getsubscribe()
				}else if(e.name==4){
					this.getadvertising()
				}
			},
			async getData() {
				this.echartLoading = true
				try {
					let result = await apiList.memberSignRecordList({})
					this.statisticalData = result.Result
					this.echartData = {
						x: [],
						y: []
					}
					let echartStatistics = this.statisticalData.SignStatistics
					this.statisticalData.SignStatistics.forEach(t => {
						this.echartData.x.push(t.DateValue)
						this.echartData.y.push(t.SignCount)
					})
					this.echartData.x.reverse()
					this.echartData.y.reverse()
				} catch (e) {
					this.echartLoading = false
				} finally {
					this.echartLoading = false
				}
			},
			//初始化获取
			async getFormData() {
				this.loading = true
				try {
					let result = await apiList.signConfigModel({})
					this.ruleForm = result.Result
					if (this.ruleForm.CouponListDayDetail.length) {
						this.ruleForm.CouponListDayDetail[0].CouponId = this.ruleForm.CouponListDayDetail[0].Id
					}
					this.currentCycle = result.Result.ContinueSignCycle
				} catch (e) {
					this.loading = false
				} finally {
					this.loading = false
				}
			},
			async getsubscribe(){
				this.echartLoading = true
				try{
					let res = await apiList.SignConfigSubscribeInit()
					if(res.IsSuccess){
						// console.log(res.Result)
						this.subscribemsg = res.Result
					}
				}finally{
					this.echartLoading = false
				}
			},
			async getadvertising(){
				try{
					this.echartLoading = true
					let res = await apiList.SignConfigAdvertiseSpaceInit()
					if(res.IsSuccess){
						// console.log(res.Result)
						this.advertisinglist = res.Result
						this.picturecheck = res.Result.some((v)=>{return v.AdvertiseSpaceType==0})
						this.shopcheck = res.Result.some((v)=>{return v.AdvertiseSpaceType==1})
						if(this.shopcheck){
							this.selectedList = res.Result.find((v)=>{return v.AdvertiseSpaceType==1}).RelatedAdvertiseProductToSave
						}
						// console.log(res.Result)
					}
				}finally{
					this.echartLoading = false
				}
			},
			//编辑日常奖励
			handleDailyCheckReward(index) {
				this.$nextTick(() => {
					this.dailySelectDialog = true
				})
				if (index == 0) {
					this.dailySelect = {
						IsSendPointDay: true,
						PointNumDay: '',
						CouponListDayDetail: [],
					}
				} else {
					this.dailySelect = {
						CouponListDayDetail: this.ruleForm.CouponListDayDetail,
						IsSendPointDay: this.ruleForm.IsSendPointDay,
						PointNumDay: this.ruleForm.PointNumDay
					}
				}
				this.$nextTick(() => {
					this.$refs['dailyForm'].clearValidate()
				})
			},
			//删除日常奖励
			handleDeleteDailyCheckReward() {
				this.dailySelect = {
					IsSendPointDay: true,
					PointNumDay: '',
					CouponListDayDetail: [],
				}
				this.ruleForm.PointNumDay = ''
				this.ruleForm.CouponListDayDetail = []
				this.ruleForm.IsSendPointDay = false
				this.ruleForm.IsSendCouponDay = false
			},
			//删除优惠券
			handleDeleteDailyCoupon() {
				this.dailySelect = {
					IsSendPointDay: false,
					PointNumDay: '',
					CouponListDayDetail: []
				}
			},
			// 保存日签奖励选择
			handleSaveDailySelect() {
				if (this.dailySelect.IsSendPointDay) {
					if (this.dailySelect.PointNumDay == '' || this.dailySelect.PointNumDay == 0) {
						this.$message({showClose: true,
							type: 'error',
							message: '请输入积分'
						})
					} else {
						this.ruleForm.PointNumDay = this.dailySelect.PointNumDay
						this.dailySelectDialog = false

						this.ruleForm.IsSendPointDay = this.dailySelect.IsSendPointDay
						this.ruleForm.IsSendCouponDay = !this.dailySelect.IsSendPointDay
					}
				} else {
					if(!this.dailySelect.CouponListDayDetail.length){
						this.$message({showClose: true,
							type: 'error',
							message:'请选择优惠券',
						})
					}else{
						if (!this.dailySelect.CouponListDayDetail[0].CouponCount) {
							this.$message({showClose: true,
								type: 'error',
								message: '请输入赠送数量'
							})
						} else {
							this.ruleForm.CouponListDayDetail = this.dailySelect.CouponListDayDetail
							this.ruleForm.CouponListDayDetail[0].CouponId = this.dailySelect.CouponListDayDetail[0].Id
							this.ruleForm.CouponListDayDetail[0].CouponCount = this.dailySelect.CouponListDayDetail[0].CouponCount
							this.ruleForm.CouponListDayDetail[0].CouponName = this.dailySelect.CouponListDayDetail[0].CouponName
							this.dailySelectDialog = false
							this.ruleForm.IsSendPointDay = this.dailySelect.IsSendPointDay
							this.ruleForm.IsSendCouponDay = !this.dailySelect.IsSendPointDay

						}
					}
				}
			},
			handleDailySelect(e) {
				if (e) {
					this.dailySelect.CouponListDayDetail = []
				} else {
					this.dailySelect.PointNumDay = ''
				}
			},
			//日签奖励，选择优惠券
			handleSelectCouponShow() {
				let id = this.dailySelect.CouponListDayDetail.length == 0 ? 0 : this.dailySelect.CouponListDayDetail[0].Id
				this.couponCheckedId = id
				this.selectCouponShow = true
			},
			cancelAddCoupon() {
				this.dailySelectDialog = false
			},
			//获取日签优惠券
			getSelectCoupon(data, val) {
				// 空对象
				data.CouponCount = ''
				if (val) {
					this.dailySelect.CouponListDayDetail = []
					this.dailySelect.CouponListDayDetail.push(data)
				}
				this.selectCouponShow = false
			},

			//连签奖励，选择优惠券
			handleCoutinueCoupons() {
				let id = this.continueSelect.CouponListDetail.length ? this.continueSelect.CouponListDetail[0].Id : 0
				this.couponCheckedId = id
				this.ContinueSelectCoupon = true
			},
			//获取连续签到优惠券
			getContinueSelectCoupon(data, val) {
				data.CouponCount = ''
				if (val) {
					this.continueSelect.CouponListDetail = []
					this.continueSelect.CouponListDetail.push(data)
				}
				this.ContinueSelectCoupon = false
			},
			//连签奖励，选择商品
			handleCoutinuePro() {
				let id = this.continueSelect.GiftListDetail.length ? this.continueSelect.GiftListDetail[0].ProductSpecId : 0
				this.proCheckedId = id
				this.selectProShow = true
			},
			//获取选择的商品列表
			getSelectList(data, val) {
				data.ProductCount = ''
				if (val) {
					this.continueSelect.GiftListDetail = []
					this.continueSelect.GiftListDetail.push(data)
					this.continueSelect.GiftListDetail[0].ProductSpec = data.SpecValue
					this.continueSelect.GiftListDetail[0].State = data.SpecValue

					// Stock (int): 库存,
					// AddTime (Date): 添加时间,
					// IsSupplierProduct (boolean): 是否为分销商品,
					// IsOpen (boolean): 是否上架,
				}
				this.selectProShow = false
			},
			handleContinuePointCheck(e) {
				if (!e) {
					this.continueSelect.PointNum = ''
				}
			},
			handleContinueCouponCheck(e) {
				if (!e) {
					this.continueSelect.CouponListDetail = []
				}
			},
			handleContinueProCheck(e) {
				if (!e) {
					this.continueSelect.GiftListDetail = []
				}
			},
			//删除连签奖励，优惠券
			deleteContinuCoupon() {
				this.continueSelect.CouponListDetail = []
			},
			//删除连签奖励，赠品
			deleteContinuPro() {
				this.continueSelect.GiftListDetail = []
			},
			//添加连续奖励
			handleAddContinueReward(val, index) {
				if (this.ruleForm.ContinueSignCycle != '' && this.ruleForm.ContinueSignCycle != 0) {
					if (index == undefined) {
						this.continueEditIndex = null
					} else {
						this.continueEditIndex = index
					}
					//先清空
					this.continueSelect = {
						ContinueSignDay: '',
						CouponList: [],
						CouponListDetail: [],
						GiftList: [],
						GiftListDetail: [],
						IsSendCoupon: false,
						IsSendGift: false,
						IsSendPoint: false,
						PointNum: ''
					}
					if (val) {
						this.continueSelect = Object.assign({}, val)
					}
					this.editContinueDialog = true
					this.$nextTick(() => {
						this.$refs['continueForm'].clearValidate()
					})
				} else {
					this.$message({showClose: true,
						type: 'info',
						message: '请设置连签周期'
					})
				}
			},
			//删除连续奖励
			handleDeleteContinueReward(val) {
				this.ruleForm.ContinueSignSignConfigDetail.splice(val, 1)
			},
			//保存连续签到奖励
			saveAddContinueReward(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if ((!this.continueSelect.IsSendPoint) && (!this.continueSelect.IsSendCoupon) && (!this.continueSelect.IsSendGift)) {
							this.$message({showClose: true,
								type: 'error',
								message: '请选择奖励'
							})
						} else if (this.continueSelect.IsSendPoint && this.continueSelect.PointNum == '') {
							this.$message({showClose: true,
								type: 'error',
								message: '请设置积分'
							})
						} else if (this.continueSelect.IsSendCoupon && this.continueSelect.CouponListDetail.length == 0) {
							this.$message({showClose: true,
								type: 'error',
								message: '请选择优惠券'
							})
						} else if (this.continueSelect.IsSendCoupon && this.continueSelect.CouponListDetail[0].CouponCount == 0) {
							this.$message({showClose: true,
								type: 'error',
								message: '请填写赠送数量'
							})
						} else if (this.continueSelect.IsSendGift && this.continueSelect.GiftListDetail.length == 0) {
							this.$message({showClose: true,
								type: 'error',
								message: '请选择商品'
							})
						} else if (this.continueSelect.IsSendGift && this.continueSelect.GiftListDetail[0].ProductCount == 0) {
							this.$message({showClose: true,
								type: 'error',
								message: '请填写赠送数量'
							})
						} else {
							let value = this.continueSelect
							if (this.continueEditIndex == null) {
								this.ruleForm.ContinueSignSignConfigDetail.push(value)
							} else {
								this.$set(this.ruleForm.ContinueSignSignConfigDetail, this.continueEditIndex, value)
							}
							this.$forceUpdate()
							this.editContinueDialog = false
						}
					} else {
						console.log('error submit')
					}
					console.log("this.ruleForm.ContinueSignSignConfigDetail",this.ruleForm.ContinueSignSignConfigDetail)
				})
			},
			async saveEditData() {
				this.loading = true
				try {
					let data = {
						ContinueSignCycle: this.ruleForm.ContinueSignCycle,
						ContinueSignSignConfig: this.ruleForm.ContinueSignSignConfigDetail,
						CouponListDay: this.ruleForm.CouponListDay,
						IsSendCouponDay: this.ruleForm.IsSendCouponDay,
						IsSendPointDay: this.ruleForm.IsSendPointDay,
						PointNumDay: this.ruleForm.PointNumDay,
						SignRules: this.ruleForm.SignRules,
						Title: this.ruleForm.Title,
					}
					console.log("this.ruleForm.ContinueSignSignConfigDetail,",this.ruleForm.ContinueSignSignConfigDetail,)
					let result = await apiList.signConfigEdit(data)
					if (result.IsSuccess) {
						this.$message({showClose: true,
							type: 'success',
							message: '保存成功'
						})
						this.getFormData()
					}
				} catch (e) {
					this.loading = false
				} finally {
					this.loading = false
				}
			},
			//保存
			saveEditForm() {
				this.$refs['ruleForm'].validate(async (valid) => {
					if (valid) {
						if (this.ruleForm.CouponListDay && this.ruleForm.IsSendCouponDay) {
							this.ruleForm.CouponListDay[0] = {
								CouponId: this.ruleForm.CouponListDayDetail[0].Id,
								CouponCount: this.ruleForm.CouponListDayDetail[0].CouponCount,
								CouponName: this.ruleForm.CouponListDayDetail[0].CouponName,
							}
						} else {
							this.ruleForm.CouponListDay = []
						}

						this.ruleForm.ContinueSignSignConfigDetail.forEach(t => {
							if (t.CouponListDetail && t.CouponListDetail.length) {
								t.CouponList[0] = {
									CouponId: t.CouponListDetail[0].Id,
									CouponCount: t.CouponListDetail[0].CouponCount,
									CouponName: t.CouponListDetail[0].CouponName
								}
							} else {
								t.CouponList = []
							}
							if (t.GiftListDetail && t.GiftListDetail.length) {
								t.GiftList[0] = {
									ProductId: t.GiftListDetail[0].ProductId || t.GiftListDetail[0].Id,
									ProductSpecId: t.GiftListDetail[0].ProductSpecId,
									ProductCount: t.GiftListDetail[0].ProductCount,
									ProductName: t.GiftListDetail[0].Name
								}
							} else {
								t.GiftList = []
							}
						})
						if (this.currentCycle != this.ruleForm.ContinueSignCycle&&this.currentCycle!==0) {
							this.$confirm('您正在修改周期天数，修改成功后的周期次日生效，现周期今日结束。请确认是否继续修改？', '提示', {
								confirmButtonText: '继续修改',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(async () => {
								this.saveEditData()
							}).catch(() => {
								this.$message({showClose: true,
									type: 'info',
									message: '已取消修改'
								});
							})
						} else {
							this.saveEditData()
						}
					}
				})
			}
		}
	}
</script>
<style lang='less' scoped>
	.app-container {
		overflow: hidden;
		background-color: #fff;

		margin-bottom: 90px;
	}

	.basic-text-button {
		color: #409EFF;
		margin-left: 10px;
		cursor: pointer;
	}

	.error-text-button {
		color: #F56C6C;
		margin-left: 10px;
		cursor: pointer;
	}

	.margin-left-style {
		margin-left: 10px;
	}

	.tab-first {
		width: 100%;
		border: 1px solid #ddd;
		padding: 10px;

		.content {
			width: 100%;
			margin-top: 30px;
			overflow: hidden;

			.left {
				width: 800px;
				overflow: hidden;
				float: left;
				height: 500px;
				background-color: #F5F5F5;
			}

			.right {
				width: calc(100% - 820px);
				float: right;
				background-color: #F5F5F5;
				height: 500px;
				display: flex;
				flex-direction: column;
				justify-content: space-around;

				.item {
					display: flex;
					flex-direction: row;
					// justify-content: space-around;
					align-items: center;
				}

				.text {
					line-height: 50px;
					font-size: 24px;
				}
			}
		}
	}

	.tab-second {
		.daily-check-content {
			display: flex;
			flex-direction: row;

		}

		.continue-check-content {
			.remarks {
				color: #909399;
				font-size: 12px;
				display: block;
				line-height: 20px !important;
			}
		}
	}

	.dialog-check-radio {
		display: block;
		margin-bottom: 10px;

	}

	.dialog-footer {
		text-align: center;

		.button {
			width: 150px;
		}
	}

	.check-item {
		// margin-bottom: 20px;
		margin-left: 43px;
	}

	.footer {
		position: fixed;
		background-color: #fff;
		bottom: 0;
		width: 100%;
		line-height: 80px;
		text-align: center;
		left: 0;
		box-shadow: 13px 1px 6px #999;
		z-index: 999;
		margin-left: 5%;
	}

	.dialog-name-content {
		display: flex;
		flex-direction: row;

		img {
			width: 40px;
			height: 40px;
			object-fit: contain;
			margin-right: 10px;
			border: 1px solid #ddd;
		}

		.name {
			width: 304px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		span {
			color: #E51C23;
			font-size: 12px;
		}
	}
</style>
<style lang = "less" scoped>
.flexrowstart{
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	/* height: 100%; */
	margin-top:20px;
	.leftfont{
		width: 180px;
		font-size: 14px;
		font-weight: bold;
		/* border:1px solid black; */
		text-align: right;
		color: #606266;
		white-space: nowrap;
		flex: 0 0 auto;
	}
}
.flexRow{
	display: flex;
	flex-direction: row;
	align-items: center;
	/* justify-content: space-between; */
}
.grayfont{
	font-size: 12px;
	line-height: 1.5;
	color: #999999;
}
.card{
	margin-top: 20px;
	.cardtitle{
		font-size: 16px;
		color: #303133;
		line-height: 1.5;
	}
	.card-grayfont{
		font-size: 14px;
		color: #666666;
		line-height: 1.5;
		white-space: nowrap;
	}	
	.flexRow{
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 5px 0px;
		.card-leftfont{
			width: 80px;
			flex-shrink: 0;
			color: #666666;
			/* font-size: 14px; */
			/* border-bottom:1px solid #aaaaaa; */
			text-align: left;
		}
	}
}
.rightimgbox{
	width:320px;
	margin-left:15px;
	position: relative;
	/* border-radius: 2px; */
	.imgposition{
		width:100%;
		position: relative;
		top: 0px;
		left: 0px;
	}
	.contetbox{
		position: absolute;
		/* border:1px solid black; */
		width: 280px;
		/* height: 100px; */
		top: 100px;
		left:20px;
		border-radius: 7px;
		/* background: white; */
		.contetbox-top{
			padding: 15px;
			.contet-title{
				font-size: 14px;
				color: #666666;
				margin-top: 5px;
			}
		}
	}
}
.fonthidden{
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
}
.Signinleft{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	/* border:1px solid black; */
	/* height: 100%; */
	flex:1;
}

</style>
<style lang = "less" scoped>
.draggablebox{
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	/* justify-content: space-between; */
	/* border:1px solid black; */
	/* width: 90%; */
	.shopbox{
		display: flex;
		flex-direction: column;
		/* border:1px solid black; */
		width:120px;height:170px;
		margin:0px 30px 20px 0px;
		.imgbox{
			width:120px;height:120px;
			/* border:1px solid red; */
			.position-img{
				position: relative;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
				.icon-close{
					/* border:1px solid black; */
					width:16px;
					height: 16px;
					position: absolute;
					right:-8px;
					top:-8px;
					border-radius: 50%;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
					color: white;
					background: black;
					font-size: 12px;
					cursor: pointer;
				}
			}
		}
		.fontbox{
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			.fonthidden2{
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				word-break: break-all;
			}
		}
	}
}
</style>
<style>
.uploadBox .el-upload--picture-card{
	width:350px;
	height:156px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}
.tooltipitem{
	max-width: 350px;
}
.dialogpad .el-dialog__body{
	padding:10px 20px 20px 20px;
}
</style>